// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `entity-list-item-button {
  display: block;
}

entity-list-item-button:hover {
  cursor: pointer;
}

.entity-list-item-button--DisabledButton {
  opacity: 0.3;
}

.entity-list-item-button--DisabledButton:hover {
  color: inherit;
  cursor: not-allowed;
}
`, "",{"version":3,"sources":["webpack://./src/aureliaComponents/entity-list-item-button/entity-list-item-button.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,cAAc;EACd,mBAAmB;AACrB","sourcesContent":["entity-list-item-button {\n  display: block;\n}\n\nentity-list-item-button:hover {\n  cursor: pointer;\n}\n\n.entity-list-item-button--DisabledButton {\n  opacity: 0.3;\n}\n\n.entity-list-item-button--DisabledButton:hover {\n  color: inherit;\n  cursor: not-allowed;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
