// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `gallery-thing-panel {
  display: block;
}

.gallery-thing-panel--Container {
  background-color: var(--record-it-color-gray-200);
  padding-top: 22px;
  padding-bottom: 22px;
}

.gallery-thing-panel--Content > * + * {
  margin-top: 24px;
}

.gallery-thing-panel--Footer {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-end;
  margin-top: 25px;
}
`, "",{"version":3,"sources":["webpack://./src/galleryThing/gallery-thing-panel/gallery-thing-panel.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,iDAAiD;EACjD,iBAAiB;EACjB,oBAAoB;AACtB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,yBAAyB;EACzB,gBAAgB;AAClB","sourcesContent":["gallery-thing-panel {\n  display: block;\n}\n\n.gallery-thing-panel--Container {\n  background-color: var(--record-it-color-gray-200);\n  padding-top: 22px;\n  padding-bottom: 22px;\n}\n\n.gallery-thing-panel--Content > * + * {\n  margin-top: 24px;\n}\n\n.gallery-thing-panel--Footer {\n  display: flex;\n  flex-flow: row wrap;\n  justify-content: flex-end;\n  margin-top: 25px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
