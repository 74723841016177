// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `circle-preloader {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.circle-preloader--Path {
  stroke: var(--record-it-color-primary);
}

.circle-preloader--PreloaderWrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
  height: 50%;
}

/********** globalstyles **********/

.circle-preloader-big > .circle-preloader--PreloaderWrapper {
  width: 75%;
  height: 75%;
}

circle-preloader[data-background='white'] {
  background: white;
}

circle-preloader[data-fill] .circle-preloader--PreloaderWrapper {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translate(0, 0);
  width: auto;
  height: auto;
}
`, "",{"version":3,"sources":["webpack://./src/aureliaComponents/circle-preloader/circle-preloader.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,MAAM;EACN,OAAO;EACP,SAAS;EACT,QAAQ;AACV;;AAEA;EACE,sCAAsC;AACxC;;AAEA;EACE,kBAAkB;EAClB,QAAQ;EACR,SAAS;EACT,gCAAgC;EAChC,UAAU;EACV,WAAW;AACb;;AAEA,mCAAmC;;AAEnC;EACE,UAAU;EACV,WAAW;AACb;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,MAAM;EACN,QAAQ;EACR,SAAS;EACT,OAAO;EACP,0BAA0B;EAC1B,WAAW;EACX,YAAY;AACd","sourcesContent":["circle-preloader {\n  position: absolute;\n  top: 0;\n  left: 0;\n  bottom: 0;\n  right: 0;\n}\n\n.circle-preloader--Path {\n  stroke: var(--record-it-color-primary);\n}\n\n.circle-preloader--PreloaderWrapper {\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n  width: 50%;\n  height: 50%;\n}\n\n/********** globalstyles **********/\n\n.circle-preloader-big > .circle-preloader--PreloaderWrapper {\n  width: 75%;\n  height: 75%;\n}\n\ncircle-preloader[data-background='white'] {\n  background: white;\n}\n\ncircle-preloader[data-fill] .circle-preloader--PreloaderWrapper {\n  top: 0;\n  right: 0;\n  bottom: 0;\n  left: 0;\n  transform: translate(0, 0);\n  width: auto;\n  height: auto;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
