// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `report-list {
  display: block;
}

.report-list--Error {
  text-align: center;
  font-weight: 500;
  font-size: 13px;
  padding: 20px 10px;
}
`, "",{"version":3,"sources":["webpack://./src/aureliaComponents/report-list/report-list.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,kBAAkB;EAClB,gBAAgB;EAChB,eAAe;EACf,kBAAkB;AACpB","sourcesContent":["report-list {\n  display: block;\n}\n\n.report-list--Error {\n  text-align: center;\n  font-weight: 500;\n  font-size: 13px;\n  padding: 20px 10px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
