// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `process-task-info-note {
  display: block;
}

.process-task-info-note--Note {
  display: flex;
  gap: 8px;
  align-items: baseline;
  background-color: var(--record-it-color-white);
  box-shadow:
    0 4px 6px -1px rgb(0 0 0 / 0.1),
    0 2px 4px -2px rgb(0 0 0 / 0.1);
  padding: 8px;
}

.process-task-info-note--NoteContent {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
}

.process-task-info-note--NoteContentDetails {
  color: var(--record-it-color-gray-700);
  font-size: 0.8em;
}
`, "",{"version":3,"sources":["webpack://./src/operationsComponents/process-task-info-note/process-task-info-note.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,QAAQ;EACR,qBAAqB;EACrB,8CAA8C;EAC9C;;mCAEiC;EACjC,YAAY;AACd;;AAEA;EACE,cAAc;EACd,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,sCAAsC;EACtC,gBAAgB;AAClB","sourcesContent":["process-task-info-note {\n  display: block;\n}\n\n.process-task-info-note--Note {\n  display: flex;\n  gap: 8px;\n  align-items: baseline;\n  background-color: var(--record-it-color-white);\n  box-shadow:\n    0 4px 6px -1px rgb(0 0 0 / 0.1),\n    0 2px 4px -2px rgb(0 0 0 / 0.1);\n  padding: 8px;\n}\n\n.process-task-info-note--NoteContent {\n  flex: 1 1 auto;\n  display: flex;\n  flex-direction: column;\n}\n\n.process-task-info-note--NoteContentDetails {\n  color: var(--record-it-color-gray-700);\n  font-size: 0.8em;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
