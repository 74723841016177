// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `show-process-appointment-phone-buttons {
  display: block;
}

.show-process-appointment-phone-buttons--Contact_highlighted {
  background-color: var(
    --record-it-color-highlighted-person-contact-background
  );
}
`, "",{"version":3,"sources":["webpack://./src/pages/show_process_appointment/show-process-appointment-main-page-content/show-process-appointment-phone-buttons/show-process-appointment-phone-buttons.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE;;GAEC;AACH","sourcesContent":["show-process-appointment-phone-buttons {\n  display: block;\n}\n\n.show-process-appointment-phone-buttons--Contact_highlighted {\n  background-color: var(\n    --record-it-color-highlighted-person-contact-background\n  );\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
