// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `process-task-measure-point-list-item-reading {
  display: flex;
  align-items: flex-end;
  margin-bottom: 16px;
}

.process-task-measure-point-list-item-reading--ReadingDeleteButtonWrapper {
  margin-bottom: -10px;
  text-align: right;
}
`, "",{"version":3,"sources":["webpack://./src/operationsComponents/process-task-measure-point-list-widget/process-task-measure-point-list-item-reading/process-task-measure-point-list-item-reading.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,qBAAqB;EACrB,mBAAmB;AACrB;;AAEA;EACE,oBAAoB;EACpB,iBAAiB;AACnB","sourcesContent":["process-task-measure-point-list-item-reading {\n  display: flex;\n  align-items: flex-end;\n  margin-bottom: 16px;\n}\n\n.process-task-measure-point-list-item-reading--ReadingDeleteButtonWrapper {\n  margin-bottom: -10px;\n  text-align: right;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
