// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `checklist-add-inspection-question-dialog {
  display: block;
}

.checklist-add-inspection-question-dialog--ActionBar {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.checklist-add-inspection-question-dialog--InputWrapperList {
  display: block;
}

.checklist-add-inspection-question-dialog--InputWrapperList > * + * {
  margin-top: 16px;
}

.checklist-add-inspection-question-dialog--UnitListItem {
  border-radius: 6px;
  padding: 4px 10px;

  display: flex;
  align-items: center;
  flex-wrap: wrap;
  column-gap: 12px;
}

.checklist-add-inspection-question-dialog--UnitListItem > * {
  flex: 1 1 300px;
}

.checklist-add-inspection-question-dialog--UnitListItemDelete {
  flex: 0 0 auto;
}

.checklist-add-inspection-question-dialog--QuestionCategoryGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
}

.checklist-add-inspection-question-dialog--CopyCategoryInfoText {
  color: var(--record-it-color-orange);
  font-size: 13px;

  overflow: hidden;
  text-overflow: ellipsis;
}
`, "",{"version":3,"sources":["webpack://./src/dialogs/checklist-add-inspection-question-dialog/checklist-add-inspection-question-dialog.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,WAAW;EACX,aAAa;EACb,yBAAyB;AAC3B;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;EAClB,iBAAiB;;EAEjB,aAAa;EACb,mBAAmB;EACnB,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,2DAA2D;AAC7D;;AAEA;EACE,oCAAoC;EACpC,eAAe;;EAEf,gBAAgB;EAChB,uBAAuB;AACzB","sourcesContent":["checklist-add-inspection-question-dialog {\n  display: block;\n}\n\n.checklist-add-inspection-question-dialog--ActionBar {\n  width: 100%;\n  display: flex;\n  justify-content: flex-end;\n}\n\n.checklist-add-inspection-question-dialog--InputWrapperList {\n  display: block;\n}\n\n.checklist-add-inspection-question-dialog--InputWrapperList > * + * {\n  margin-top: 16px;\n}\n\n.checklist-add-inspection-question-dialog--UnitListItem {\n  border-radius: 6px;\n  padding: 4px 10px;\n\n  display: flex;\n  align-items: center;\n  flex-wrap: wrap;\n  column-gap: 12px;\n}\n\n.checklist-add-inspection-question-dialog--UnitListItem > * {\n  flex: 1 1 300px;\n}\n\n.checklist-add-inspection-question-dialog--UnitListItemDelete {\n  flex: 0 0 auto;\n}\n\n.checklist-add-inspection-question-dialog--QuestionCategoryGrid {\n  display: grid;\n  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));\n}\n\n.checklist-add-inspection-question-dialog--CopyCategoryInfoText {\n  color: var(--record-it-color-orange);\n  font-size: 13px;\n\n  overflow: hidden;\n  text-overflow: ellipsis;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
