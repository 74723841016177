// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `show-process-appointment-confirm-note-page-content {
  display: block;
  padding-top: 27px;
}

.show-process-appointment-confirm-note-page-content--Introduction {
  margin-bottom: 17px;
  font-weight: 500;
}

.show-process-appointment-confirm-note-page-content--Note {
  white-space: pre-wrap;
}

.show-process-appointment-confirm-note-page-content--ButtonsWrapper {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  margin-top: 47px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/show_process_appointment/show-process-appointment-confirm-note-page-content/show-process-appointment-confirm-note-page-content.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,iBAAiB;AACnB;;AAEA;EACE,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,gBAAgB;AAClB","sourcesContent":["show-process-appointment-confirm-note-page-content {\n  display: block;\n  padding-top: 27px;\n}\n\n.show-process-appointment-confirm-note-page-content--Introduction {\n  margin-bottom: 17px;\n  font-weight: 500;\n}\n\n.show-process-appointment-confirm-note-page-content--Note {\n  white-space: pre-wrap;\n}\n\n.show-process-appointment-confirm-note-page-content--ButtonsWrapper {\n  display: flex;\n  flex-flow: row wrap;\n  justify-content: space-between;\n  margin-top: 47px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
