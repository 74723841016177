// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `process-task-measure-point-creation-buttons {
  display: block;
}

.process-task-measure-point-creation-buttons--Title {
  font-weight: 500;
  font-size: 15px;
}

.process-task-measure-point-creation-buttons--Buttons {
  margin-left: -15px;
}

.process-task-measure-point-creation-buttons--Button {
  color: var(--record-it-color-white);
  margin-left: 15px;
  margin-top: 10px;
  min-width: 150px;
}

.process-task-measure-point-creation-buttons--Button:disabled {
  filter: grayscale(0.8);
}
`, "",{"version":3,"sources":["webpack://./src/operationsComponents/process-task-measure-point-creation-buttons/process-task-measure-point-creation-buttons.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,gBAAgB;EAChB,eAAe;AACjB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,mCAAmC;EACnC,iBAAiB;EACjB,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,sBAAsB;AACxB","sourcesContent":["process-task-measure-point-creation-buttons {\n  display: block;\n}\n\n.process-task-measure-point-creation-buttons--Title {\n  font-weight: 500;\n  font-size: 15px;\n}\n\n.process-task-measure-point-creation-buttons--Buttons {\n  margin-left: -15px;\n}\n\n.process-task-measure-point-creation-buttons--Button {\n  color: var(--record-it-color-white);\n  margin-left: 15px;\n  margin-top: 10px;\n  min-width: 150px;\n}\n\n.process-task-measure-point-creation-buttons--Button:disabled {\n  filter: grayscale(0.8);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
