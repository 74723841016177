// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `process-task-log-entries-viewer {
  display: block;
}

.process-task-log-entries-viewer--FooterRestoreButton {
  background: inherit;
  border: 0;
  padding: 8px;
  margin: -8px;
  color: var(--record-it-color-gray-600);
  font-size: 0.8em;
  text-decoration: underline;
}

.process-task-log-entries-viewer--FooterRestoreButton:hover {
  color: var(--record-it-color-gray-700);
}
`, "",{"version":3,"sources":["webpack://./src/operationsComponents/process-task-log-entries-viewer/process-task-log-entries-viewer.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,mBAAmB;EACnB,SAAS;EACT,YAAY;EACZ,YAAY;EACZ,sCAAsC;EACtC,gBAAgB;EAChB,0BAA0B;AAC5B;;AAEA;EACE,sCAAsC;AACxC","sourcesContent":["process-task-log-entries-viewer {\n  display: block;\n}\n\n.process-task-log-entries-viewer--FooterRestoreButton {\n  background: inherit;\n  border: 0;\n  padding: 8px;\n  margin: -8px;\n  color: var(--record-it-color-gray-600);\n  font-size: 0.8em;\n  text-decoration: underline;\n}\n\n.process-task-log-entries-viewer--FooterRestoreButton:hover {\n  color: var(--record-it-color-gray-700);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
