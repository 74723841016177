// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.export-project--Warning {
  color: orange;
  padding: 20px;
  margin-bottom: 40px;
  margin-top: -40px;

  border: 1px solid orange;
  background: #f6ecdc;

  text-align: center;
  font-weight: 500;
  letter-spacing: 0.8px;
}

.export-project--ReportTypeName {
  font-weight: 600;
}

.export-project--ReportList {
  margin-top: 20px;
}

@media (max-width: 767px) {
  .export-project--Warning {
    margin-bottom: 26px;
    margin-top: -26px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/export_project/export_project.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,aAAa;EACb,mBAAmB;EACnB,iBAAiB;;EAEjB,wBAAwB;EACxB,mBAAmB;;EAEnB,kBAAkB;EAClB,gBAAgB;EAChB,qBAAqB;AACvB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE;IACE,mBAAmB;IACnB,iBAAiB;EACnB;AACF","sourcesContent":[".export-project--Warning {\n  color: orange;\n  padding: 20px;\n  margin-bottom: 40px;\n  margin-top: -40px;\n\n  border: 1px solid orange;\n  background: #f6ecdc;\n\n  text-align: center;\n  font-weight: 500;\n  letter-spacing: 0.8px;\n}\n\n.export-project--ReportTypeName {\n  font-weight: 600;\n}\n\n.export-project--ReportList {\n  margin-top: 20px;\n}\n\n@media (max-width: 767px) {\n  .export-project--Warning {\n    margin-bottom: 26px;\n    margin-top: -26px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
