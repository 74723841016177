// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `checklist_inspection {
  display: block;
}

.checklist-inspection--Stack > * + * {
  margin-block-start: 64px;
}

.checklist-inspection--Details {
  margin-bottom: 0;
}

.checklist-inspection--ProgressBarLabel {
  font-size: 0.8em;
}

.checklist-inspection--CategoryGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 12px;
}

.checklist-inspection--CategoryGridItem {
  padding: 2em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.checklist-inspection--CategoryTitle {
  font-weight: 500;
}

.checklist-inspection--CategorySubTitle {
  font-size: 0.8em;
  color: var(--record-it-color-gray-600);
}

.checklist-inspection--CategoryDoneIcon {
  color: var(--record-it-color-sea-green);
  font-size: 18px;
}

.checklist-inspection--AddCategoryIcon {
  font-size: 3em;
  color: var(--record-it-color-gray-600);
}

.checklist-inspection--Buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  justify-content: center;
}

.checklist-inspection--Buttons > * {
  flex: 0 0 260px;
  padding: 1.2em 3em;
}

.checklist-inspection--Footer {
  text-align: center;
}
`, "",{"version":3,"sources":["webpack://./src/pages/checklist_inspection/checklist_inspection.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,wBAAwB;AAC1B;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,4DAA4D;EAC5D,SAAS;AACX;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;EAChB,sCAAsC;AACxC;;AAEA;EACE,uCAAuC;EACvC,eAAe;AACjB;;AAEA;EACE,cAAc;EACd,sCAAsC;AACxC;;AAEA;EACE,aAAa;EACb,eAAe;EACf,QAAQ;EACR,uBAAuB;AACzB;;AAEA;EACE,eAAe;EACf,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;AACpB","sourcesContent":["checklist_inspection {\n  display: block;\n}\n\n.checklist-inspection--Stack > * + * {\n  margin-block-start: 64px;\n}\n\n.checklist-inspection--Details {\n  margin-bottom: 0;\n}\n\n.checklist-inspection--ProgressBarLabel {\n  font-size: 0.8em;\n}\n\n.checklist-inspection--CategoryGrid {\n  display: grid;\n  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));\n  gap: 12px;\n}\n\n.checklist-inspection--CategoryGridItem {\n  padding: 2em;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n}\n\n.checklist-inspection--CategoryTitle {\n  font-weight: 500;\n}\n\n.checklist-inspection--CategorySubTitle {\n  font-size: 0.8em;\n  color: var(--record-it-color-gray-600);\n}\n\n.checklist-inspection--CategoryDoneIcon {\n  color: var(--record-it-color-sea-green);\n  font-size: 18px;\n}\n\n.checklist-inspection--AddCategoryIcon {\n  font-size: 3em;\n  color: var(--record-it-color-gray-600);\n}\n\n.checklist-inspection--Buttons {\n  display: flex;\n  flex-wrap: wrap;\n  gap: 8px;\n  justify-content: center;\n}\n\n.checklist-inspection--Buttons > * {\n  flex: 0 0 260px;\n  padding: 1.2em 3em;\n}\n\n.checklist-inspection--Footer {\n  text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
