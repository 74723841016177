// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `gallery-thing-entry-to-person-widget-person {
  display: block;
}
`, "",{"version":3,"sources":["webpack://./src/galleryThing/gallery-thing-entry-to-person-widget/gallery-thing-entry-to-person-widget-person/gallery-thing-entry-to-person-widget-person.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB","sourcesContent":["gallery-thing-entry-to-person-widget-person {\n  display: block;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
