// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `process-task-info-notes {
  display: block;
}

.process-task-info-notes--NoteContainer {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  row-gap: 24px;
  column-gap: 12px;
}

.process-task-info-notes--NoteCategory {
  padding: 6px;
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  max-height: 300px;
  overflow-y: auto;
}

.process-task-info-notes--AddNoteButton {
  border: 1px dashed var(--record-it-color-gray-700);
  background: none;
  color: var(--record-it-color-gray-800);
  cursor: pointer;
  font-size: 12px;
  padding: 6px 10px;
}

.process-task-info-notes--AddNoteButton:hover {
  border-style: solid;
}
`, "",{"version":3,"sources":["webpack://./src/operationsComponents/process-task-info-notes/process-task-info-notes.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,4DAA4D;EAC5D,aAAa;EACb,gBAAgB;AAClB;;AAEA;EACE,YAAY;EACZ,kBAAkB;EAClB,aAAa;EACb,sBAAsB;EACtB,SAAS;EACT,iBAAiB;EACjB,gBAAgB;AAClB;;AAEA;EACE,kDAAkD;EAClD,gBAAgB;EAChB,sCAAsC;EACtC,eAAe;EACf,eAAe;EACf,iBAAiB;AACnB;;AAEA;EACE,mBAAmB;AACrB","sourcesContent":["process-task-info-notes {\n  display: block;\n}\n\n.process-task-info-notes--NoteContainer {\n  display: grid;\n  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));\n  row-gap: 24px;\n  column-gap: 12px;\n}\n\n.process-task-info-notes--NoteCategory {\n  padding: 6px;\n  border-radius: 3px;\n  display: flex;\n  flex-direction: column;\n  gap: 12px;\n  max-height: 300px;\n  overflow-y: auto;\n}\n\n.process-task-info-notes--AddNoteButton {\n  border: 1px dashed var(--record-it-color-gray-700);\n  background: none;\n  color: var(--record-it-color-gray-800);\n  cursor: pointer;\n  font-size: 12px;\n  padding: 6px 10px;\n}\n\n.process-task-info-notes--AddNoteButton:hover {\n  border-style: solid;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
