// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `property-definition-widget {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 32px;
}

.property-definition-widget--Settings {
  grid-column: span 2;
  flex: 0 1 auto;
}

.property-definition-widget--DefaultValueInput {
  grid-column: span 3;
  padding: 1em;
  align-self: start;
}

.property-definition-widget--DefaultValueInput > base-property-widget {
  margin: auto;
}

.property-definition-widget--OptionsWidget {
  flex-grow: 1;
}

@media (max-width: 767px) {
  .property-definition-widget--Settings {
    grid-column: span 5;
  }

  .property-definition-widget--DefaultValueInput {
    grid-column: span 5;
  }
}
`, "",{"version":3,"sources":["webpack://./src/propertyComponents/property-definition-widget/property-definition-widget.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,qCAAqC;EACrC,SAAS;AACX;;AAEA;EACE,mBAAmB;EACnB,cAAc;AAChB;;AAEA;EACE,mBAAmB;EACnB,YAAY;EACZ,iBAAiB;AACnB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,YAAY;AACd;;AAEA;EACE;IACE,mBAAmB;EACrB;;EAEA;IACE,mBAAmB;EACrB;AACF","sourcesContent":["property-definition-widget {\n  display: grid;\n  grid-template-columns: repeat(5, 1fr);\n  gap: 32px;\n}\n\n.property-definition-widget--Settings {\n  grid-column: span 2;\n  flex: 0 1 auto;\n}\n\n.property-definition-widget--DefaultValueInput {\n  grid-column: span 3;\n  padding: 1em;\n  align-self: start;\n}\n\n.property-definition-widget--DefaultValueInput > base-property-widget {\n  margin: auto;\n}\n\n.property-definition-widget--OptionsWidget {\n  flex-grow: 1;\n}\n\n@media (max-width: 767px) {\n  .property-definition-widget--Settings {\n    grid-column: span 5;\n  }\n\n  .property-definition-widget--DefaultValueInput {\n    grid-column: span 5;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
