// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `process-task-sharepoint-upload-widget {
  display: block;
}

.process-task-sharepoint-upload-widget--UploadZone {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  height: 200px;
}

.process-task-sharepoint-upload-widget--NextFilesButton {
  margin-top: 8px;
}
`, "",{"version":3,"sources":["webpack://./src/operationsComponents/process-task-sharepoint-upload-widget/process-task-sharepoint-upload-widget.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,eAAe;EACf,qBAAqB;EACrB,uBAAuB;EACvB,aAAa;AACf;;AAEA;EACE,eAAe;AACjB","sourcesContent":["process-task-sharepoint-upload-widget {\n  display: block;\n}\n\n.process-task-sharepoint-upload-widget--UploadZone {\n  display: flex;\n  flex-wrap: wrap;\n  align-content: center;\n  justify-content: center;\n  height: 200px;\n}\n\n.process-task-sharepoint-upload-widget--NextFilesButton {\n  margin-top: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
